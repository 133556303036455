// src/App.js
import React, { useState } from "react";
// import AllRoutes from "./routes/AllRoutes"; // Import your defined routes
import Route from "./routes/index";
import "./assets/scss/themes.scss";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import HtmlThemeSwitcher from "./actions/layout/actions";
const App = () => {
  const [theme, setTheme] = useState("light");

  // const toggleTheme = () => {
  //   setTheme((prevTheme) => (prevTheme === "light" ? "dark" : "light"));
  // };

  return (
    <>
      <HtmlThemeSwitcher theme={theme} />
      <div className="d-flex flex-wrap flex-column justify-content-between; min-vh-100 position-relative">
        <Header />
        <main className="mb-auto">
          <Route />
        </main>
        <Footer />
        {/* <button onClick={toggleTheme}>
          Switch to {theme === "light" ? "Dark" : "Light"} Theme
        </button> */}
      </div>
    </>
  );
};

export default App;
