import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Form,
  Label,
  Input,
  Button,
  FormGroup,
  FormFeedback,
  CardHeader,
} from "reactstrap";
import Select from "react-select";
import "./CustomerForm.scss";
import FormSuccess from "./FormSuccess";
import Banner from "../../assets/images/mattress-exchange.png";
import { Image } from "react-bootstrap";

function CustomerForm() {
  const [formData, setFormData] = useState({
    name: "",
    phoneNumber: "",
    email: "",
    discount: "",
    model: "",
    mattressCondition: "",
    noOfMattresses: "", // Added to track selected number of mattresses
    acceptTerms: false,
  });
  const isFormValid = () => {
    return (
      formData?.name &&
      formData?.phoneNumber &&
      formData?.email &&
      formData?.discount &&
      formData?.model &&
      formData?.mattressCondition &&
      formData?.noOfMattresses &&
      formData?.acceptTerms
    );
  };

  const mattressConditionLabel = [
    { value: "Good", label: "In good condition" },
    { value: "Stained", label: "Stained" },
    { value: "Slightly Dented", label: "Slightly Dented" },
    { value: "Infested", label: "Infested with mites/bugs" },
  ];

  const discountLabel = [
    { value: "5", label: "5% Off" },
    { value: "10", label: "10% Off" },
    { value: "15", label: "15% Off" },
    { value: "20", label: "20% Off" },
  ];

  const modelList = {
    5: [
      { name: "Alento" },
      { name: "Aloevera" },
      { name: "Bala" },
      { name: "Bonne" },
      { name: "Camerota" },
      { name: "Charcoal" },
      { name: "Cloud" },
      { name: "Comfy" },
      { name: "Cool" },
      { name: "Copper" },
      { name: "Cozy" },
      { name: "DANUBE" },
      { name: "DCon" },
      { name: "DMAX" },
      { name: "Dream" },
      { name: "Dry" },
      { name: "Feel" },
      { name: "Fusion" },
      { name: "Gel" },
      { name: "Houston" },
      { name: "iSleep" },
      { name: "Layla" },
      { name: "Luxurious" },
      { name: "Madeline" },
      { name: "Magnum" },
      { name: "Marhaba" },
      { name: "Mattress" },
      { name: "MaxVibe" },
      { name: "Medicated" },
      { name: "Natura" },
      { name: "Natural" },
      { name: "Nature" },
      { name: "Organic" },
      { name: "Palinuro" },
      { name: "Prime" },
      { name: "RAHA" },
      { name: "Relax10" },
      { name: "Relax20" },
      { name: "Rest" },
      { name: "RFF" },
      { name: "Seaqual" },
      { name: "SHARAZAD" },
      { name: "Sleep" },
      { name: "Tekno" },
      { name: "TRIO" },
      { name: "WARDA" },
    ],
    10: [
      { name: "Best" },
      { name: "Bold" },
      { name: "Diamond" },
      { name: "Five" },
      { name: "LATEX" },
      { name: "Moon" },
      { name: "Plush" },
      { name: "Sense" },
    ],
    15: [
      { name: "Aloe" },
      { name: "Bamboo" },
      { name: "Eco" },
      { name: "Fiesta" },
      { name: "Rise" },
      { name: "Value" },
    ],
    20: [
      { name: "Sun" },
      { name: "Active" },
      { name: "ATLAS" },
      { name: "Blanche" },
      { name: "CARMONA" },
      { name: "Dcontour" },
      { name: "Ergo" },
      { name: "Euro" },
      { name: "Fresh" },
      { name: "Galaxy" },
      { name: "Honey" },
      { name: "Imperiale" },
      { name: "Jolie" },
      { name: "Joy" },
      { name: "Lux" },
      { name: "Materasso" },
      { name: "Ortho" },
      { name: "Passione" },
      { name: "Ravine" },
      { name: "Smart" },
      { name: "Snooze" },
      { name: "Terra" },
      { name: "TMF" },
    ],
  };

  const [errors, setErrors] = useState({});
  const [formSubmitted, setFormSubmitted] = useState(false); // State for form submission
  const [couponCode, setCouponCode] = useState("COUPONCODE");
  const [errorMessage, setErrorMessage] = useState({});
  const mattressOptions = 5; // Maximum number of mattresses allowed to exchange

  const validateForm = () => {
    const newErrors = {};

    if (!formData?.name) {
      newErrors.name = "Name is required";
    }

    if (!formData?.phoneNumber) {
      newErrors.phoneNumber = "Phone number is required";
    } else if (!/^05\d{8}$/.test(formData?.phoneNumber)) {
      newErrors.phoneNumber =
        "Invalid UAE phone number format (e.g., 0565800000)";
    }

    if (!formData?.email) {
      newErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Invalid email format";
    }

    if (!formData?.model) {
      newErrors.model = "Please select an item";
    }

    if (!formData?.mattressCondition) {
      newErrors.mattressCondition = "Please select a mattress condition";
    }

    if (!formData?.noOfMattresses) {
      newErrors.noOfMattresses = "Please select the number of mattresses";
    }

    if (!formData?.acceptTerms) {
      newErrors.acceptTerms = "You must accept the terms and conditions";
    }

    return newErrors;
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    // Clear the error message for the field being updated
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));

    // Update formData state
    const newFormData = {
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    };

    // Calculate discount percentage if typeOfMattress changes

    setFormData(newFormData);
  };

  const handleSelectChange = (selectedOption, { name }) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: selectedOption ? selectedOption?.value : "",
      model: name === "discount" ? "" : prevData?.model, // Reset model if discount changes
    }));

    // Clear any errors for the field being updated
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const handleItemsChange = (selectedItem) => {
    setFormData((prevData) => ({
      ...prevData,
      model: selectedItem ? selectedItem?.value : "", // Store the single selected item
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData);
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length === 0) {
      // Form is valid, submit data
      fetch("https://dnd.danubehome.com/api/v1/promotions/send", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJEYW51YmUgSG9tZSBKV1QiLCJhdWQiOiJEYW51YmUgSG9tZSBKV1QiLCJzdWIiOiJEYW51YmUgSG9tZSBKV1QiLCJpYXQiOjE2ODM3MDI5NzF9.L8MNoE_g8Rb1zE25HaR0vgiEvnFL6RLvLrU62Z0-PZg",
        },
        body: JSON.stringify(formData),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data?.status == "success") {
            // console.log("Form submission successful:", data);
            setFormSubmitted(true);
            setCouponCode(data?.data?.couponCode);
            // Reset the form
            setFormData({});
          } else {
            setErrorMessage(data);
          }
        })
        .catch((error) => {
          console.error("Form submission error:", error);
        });
    } else {
      setErrors(validationErrors);
    }
  };

  const renderMattressOptions = () => {
    const buttons = [];
    for (let i = 1; i <= mattressOptions; i++) {
      buttons.push(
        <React.Fragment key={i}>
          <Input
            type="radio"
            className="btn-check shadow-0"
            name="noOfMattresses"
            id={`noOfMattresses${i}`}
            value={i}
            checked={formData?.noOfMattresses === `${i}`}
            onChange={handleChange}
            invalid={!!errors.noOfMattresses}
          />
          <Label
            className="btn btn-outline-secondary shadow-0 custom-hover"
            htmlFor={`noOfMattresses${i}`}
          >
            {i}
          </Label>
        </React.Fragment>
      );
    }
    return buttons;
  };

  return (
    <Container fluid className="py-5 position-relative" style={{ zIndex: 2 }}>
      <Row>
        <Col md={6} lg={6} xl={5} xxl={4} className="ms-auto">
          <div className="sticky-scroll d-flex align-items-center h-100 w-100">
            <Image src={Banner} width={"100%"} />
          </div>
        </Col>
        <Col md={6} lg={6} xl={5} xxl={4} className="me-auto">
          <Card className="bg-white border-0 rounded  mb-0 p-3">
            {formSubmitted ? (
              <FormSuccess couponCode={couponCode} />
            ) : (
              <>
                {errorMessage.message ? (
                  <div
                    className="alert alert-warning p-2 fs-11 text-center"
                    role="alert"
                  >
                    {errorMessage.message}
                  </div>
                ) : (
                  ""
                )}

                <CardHeader border-border-bottom>
                  <h5 className="text-center">
                    Thank you for your interest in our Great Mattress Exchange!
                    Tell us more about your preferences.
                  </h5>
                </CardHeader>
                <CardBody>
                  <Form
                    className="needs-validation"
                    onSubmit={handleSubmit}
                    noValidate
                  >
                    <Row>
                      <Col md={6}>
                        <FormGroup className="mb-4">
                          <Label
                            for="nameInput"
                            className="form-label fw-light"
                          >
                            Name
                          </Label>
                          <Input
                            required
                            id="nameInput"
                            name="name"
                            value={formData?.name}
                            onChange={handleChange}
                            invalid={!!errors.name}
                            placeholder="Enter your Name"
                          />
                          <FormFeedback>{errors.name}</FormFeedback>
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup className="mb-4">
                          <Label
                            htmlFor="phoneNumberInput"
                            className="form-label fw-light"
                          >
                            Phone Number
                          </Label>
                          <Input
                            type="tel"
                            className="form-control"
                            name="phoneNumber"
                            id="phoneNumberInput"
                            value={formData?.phoneNumber}
                            onChange={handleChange}
                            invalid={!!errors.phoneNumber}
                            placeholder="0565800000"
                          />
                          <FormFeedback>{errors.phoneNumber}</FormFeedback>
                        </FormGroup>
                      </Col>
                      <Col md={12}>
                        <FormGroup className="mb-4">
                          <Label
                            htmlFor="emailidInput"
                            className="form-label fw-light"
                          >
                            Email Address
                          </Label>
                          <Input
                            type="email"
                            className="form-control"
                            name="email"
                            id="emailidInput"
                            value={formData?.email}
                            onChange={handleChange}
                            invalid={!!errors.email}
                            placeholder="example@gmail.com"
                          />
                          <FormFeedback>{errors.email}</FormFeedback>
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup className="mb-4">
                          <Label
                            htmlFor="Discount"
                            className="form-label fw-light"
                          >
                            Select discount
                          </Label>

                          <Select
                            id="discountInput"
                            name="discount"
                            placeholder="Select discount"
                            value={discountLabel.find(
                              (option) => option?.value === formData?.discount
                            )}
                            onChange={handleSelectChange}
                            options={discountLabel}
                            isClearable
                            className={errors?.discount ? "is-invalid" : ""}
                          />
                          <FormFeedback>{errors?.discount}</FormFeedback>
                        </FormGroup>
                      </Col>

                      <Col md={6}>
                        <FormGroup className="mb-4">
                          <Label
                            htmlFor="modelInput"
                            className="form-label fw-light"
                          >
                            Select model
                          </Label>

                          <Select
                            id="modelInput"
                            name="model"
                            placeholder="Select an item"
                            value={
                              formData?.model
                                ? {
                                    value: formData?.model,
                                    label: formData?.model,
                                  }
                                : ""
                            }
                            onChange={handleItemsChange}
                            options={modelList[formData?.discount]?.map(
                              (item) => ({
                                value: item?.name,
                                label: item?.name,
                              })
                            )}
                            isClearable
                            className={errors?.model ? "is-invalid" : ""}
                          />

                          <FormFeedback>{errors?.model}</FormFeedback>
                        </FormGroup>
                      </Col>

                      {/* <Col md={12}>
                        <FormGroup className="mb-4">
                          <Label
                            htmlFor="TypeOfMattress"
                            className="form-label fw-light"
                          >
                            What type of mattress do you choose?
                          </Label>

                          <Select
                            id="typeOfMattressInput"
                            name="typeOfMattress"
                            placeholder="Select a mattress type"
                            value={typeOfMattressLabel.find(
                              (option) =>
                                option.value === formData.typeOfMattress
                            )}
                            onChange={handleSelectChange}
                            options={typeOfMattressLabel}
                            isClearable
                            invalid={!!errors.typeOfMattress}
                            className={
                              errors.typeOfMattress ? "is-invalid" : ""
                            }
                          />
                          <FormFeedback>{errors.typeOfMattress}</FormFeedback>
                          
                          {formData.typeOfMattress &&
                            !errors.typeOfMattress && (
                              <div
                                className="alert bg-success text-white py-1 fs-12 mt-2"
                                role="alert"
                              >
                                You selected{" "}
                                <strong>{discountPercentage}%</strong>{" "}
                                Discounted item.
                              </div>
                            )}
                        </FormGroup>
                      </Col> */}
                      <Col md={12}>
                        <FormGroup className="mb-4">
                          <Label
                            htmlFor="FormMattressCondition"
                            className="form-label fw-light"
                          >
                            What is the current condition of the mattress you
                            want to exchange?
                          </Label>
                          <Select
                            id="mattressConditionInput"
                            name="mattressCondition"
                            placeholder="Select a mattress condition"
                            value={mattressConditionLabel.find(
                              (option) =>
                                option.value === formData.mattressCondition
                            )}
                            onChange={handleSelectChange}
                            options={mattressConditionLabel}
                            isClearable
                            invalid={!!errors.mattressCondition}
                            className={
                              errors.typeOfMattress ? "is-invalid" : ""
                            }
                          />
                          <FormFeedback>
                            {errors.mattressCondition}
                          </FormFeedback>
                        </FormGroup>
                      </Col>
                      <Col md={12}>
                        <FormGroup className="mb-4">
                          <Label
                            for="noOfMattress"
                            className="form-label fw-light"
                          >
                            How many mattresses do you wish to exchange?
                          </Label>
                          <div className="d-flex gap-2">
                            {renderMattressOptions()}
                          </div>
                          {/* Error message for number of mattresses */}
                          <FormFeedback className="d-block">
                            {errors.noOfMattresses}
                          </FormFeedback>
                        </FormGroup>
                      </Col>
                      <Col md={12}>
                        <FormGroup className="form-check form-check-dark mb-3">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="acceptTerms"
                            id="acceptTerms"
                            checked={formData.acceptTerms}
                            onChange={handleChange}
                            invalid={!!errors.acceptTerms}
                          />
                          <Label
                            className="form-check-label"
                            htmlFor="acceptTerms"
                          >
                            I agree to the terms and conditions
                          </Label>
                          <FormFeedback>{errors.acceptTerms}</FormFeedback>
                        </FormGroup>
                      </Col>
                      <Col md={12}>
                        <div className="text-start">
                          <Button
                            type="submit"
                            size="lg"
                            color="success"
                            className="rounded-pill px-4 w-100"
                            disabled={!isFormValid()} // Disable button if form is not valid
                          >
                            Submit
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </>
            )}
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default CustomerForm;
