import React, { useState, useRef } from "react";
import { CardBody, Button, Tooltip } from "reactstrap";
import "./CustomerForm.scss";
import Lottie from "lottie-react";
import animationData from "../../assets/images/lottie/giftBox.json";
function FormSuccess({ couponCode }) {
  const openInNewWindow = () => {
    window.open(
      "https://www.danubehome.com/ae/en/c/furniture/mattresses",
      "_blank"
    );
  };
  const [copied, setCopied] = useState(false);

  const handleCopyClick = () => {
    if (!couponCode) {
      console.error("Coupon code is not defined");
      return;
    }

    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(couponCode)
        .then(() => {
          setCopied(true);
          setTimeout(() => setCopied(false), 2000); // Hide "copied" status after 2 seconds
        })
        .catch((err) => console.error("Failed to copy: ", err));
    } else {
      // Fallback for older browsers or unsupported Clipboard API
      const tempInput = document.createElement("input");
      tempInput.value = couponCode;
      document.body.appendChild(tempInput);
      tempInput.select();
      document.execCommand("copy");
      document.body.removeChild(tempInput);
      setCopied(true);
      setTimeout(() => setCopied(false), 3000); // Hide "copied" status after 2 seconds
    }
  };

  return (
    <CardBody className="bg-soft-success border-success border-2 border-dashed rounded">
      <div className="text-center">
        <Lottie animationData={animationData} loop={true} />
        <div className="my-3">
          <h3 className="fw-bold text-dark">Save UPTO 20% Off!</h3>
          <p className="text-muted mb-0">
            Here’s Your coupon code but hurry! Ends - 31 SEP 2024
          </p>
          <div className="bg-light p-2 my-4 d-inline-flex align-items-center rounded shadow">
            <div className="d-flex align-items-between fs-26 fw-bold text-white py-2 px-4 rounded bg-success bg-gradient position-relative overflow-hidden">
              <div className="circle position-absolute top-50 start-0 translate-middle bg-light rounded-circle"></div>
              <div className="circle position-absolute top-50 start-100 translate-middle bg-light rounded-circle"></div>
              <span className="px-4 text-white" style={{ letterSpacing: 2 }}>
                {couponCode}
              </span>
              <span
                className="d-flex align-items-center justify-content-end"
                style={{ width: 76 }}
              >
                <span
                  style={{ lineHeight: 0, cursor: "pointer" }}
                  className="fw-light d-inline-flex justify-content-center px-2 py-1 align-items-center gap-1 fs-11 bg-white rounded-pill text-success"
                  onClick={handleCopyClick}
                >
                  {!copied ? (
                    <>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16px"
                        height="16px"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M6 11C6 8.17157 6 6.75736 6.87868 5.87868C7.75736 5 9.17157 5 12 5H15C17.8284 5 19.2426 5 20.1213 5.87868C21 6.75736 21 8.17157 21 11V16C21 18.8284 21 20.2426 20.1213 21.1213C19.2426 22 17.8284 22 15 22H12C9.17157 22 7.75736 22 6.87868 21.1213C6 20.2426 6 18.8284 6 16V11Z"
                          stroke="#45cb85"
                          strokeWidth="1.5"
                        />
                        <path
                          opacity="0.5"
                          d="M6 19C4.34315 19 3 17.6569 3 16V10C3 6.22876 3 4.34315 4.17157 3.17157C5.34315 2 7.22876 2 11 2H15C16.6569 2 18 3.34315 18 5"
                          stroke="#45cb85"
                          strokeWidth="1.5"
                        />
                      </svg>
                      Copy
                    </>
                  ) : (
                    <>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16px"
                        height="16px"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M6.59961 11.3974C6.59961 8.67119 6.59961 7.3081 7.44314 6.46118C8.28667 5.61426 9.64432 5.61426 12.3596 5.61426H15.2396C17.9549 5.61426 19.3125 5.61426 20.1561 6.46118C20.9996 7.3081 20.9996 8.6712 20.9996 11.3974V16.2167C20.9996 18.9429 20.9996 20.306 20.1561 21.1529C19.3125 21.9998 17.9549 21.9998 15.2396 21.9998H12.3596C9.64432 21.9998 8.28667 21.9998 7.44314 21.1529C6.59961 20.306 6.59961 18.9429 6.59961 16.2167V11.3974Z"
                          fill="#45cb85"
                        />
                        <path
                          opacity="0.5"
                          d="M4.17157 3.17157C3 4.34315 3 6.22876 3 10V12C3 15.7712 3 17.6569 4.17157 18.8284C4.78913 19.446 5.6051 19.738 6.79105 19.8761C6.59961 19.0353 6.59961 17.8796 6.59961 16.2167V11.3974C6.59961 8.6712 6.59961 7.3081 7.44314 6.46118C8.28667 5.61426 9.64432 5.61426 12.3596 5.61426H15.2396C16.8915 5.61426 18.0409 5.61426 18.8777 5.80494C18.7403 4.61146 18.4484 3.79154 17.8284 3.17157C16.6569 2 14.7712 2 11 2C7.22876 2 5.34315 2 4.17157 3.17157Z"
                          fill="#45cb85"
                        />
                      </svg>
                      Copied
                    </>
                  )}
                </span>
              </span>
            </div>
          </div>

          <p
            className="fs-12 lh-lg text-muted pb-3 m-auto"
            style={{ maxWidth: 460 }}
          >
            Please redeem this coupon code on any Mattress before September 31
            to receive an instant Upto 20% off at checkout!
          </p>
          <p className="fs-12 lh-lg text-muted mb-5">
            <em>*This coupon code valid in offer in-store or online</em>
          </p>
          <Button
            size="xl"
            color="dark"
            className="fs-18"
            onClick={openInNewWindow}
          >
            Shop Now
          </Button>
        </div>
      </div>
    </CardBody>
  );
}

export default FormSuccess;
