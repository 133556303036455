// src/Footer.js
import React from "react";
import { Col, Container, Row } from "reactstrap";

function ExchangeInfo() {
  return (
    <div className="bg-light">
      <Container>
        <Row>
          <Col className="text-start py-5">
            <h5 className="mb-3 text-dark">
              How Does the Mattress Exchange Work?
            </h5>
            <p className="mb-2 text-muted fs-12">
              <strong className="text-dark">Check Eligibility:</strong> Make
              sure your old mattress qualifies for our exchange program. It
              should be in a reasonable condition without the infestation of
              mites or bugs.
            </p>
            <p className="mb-2 text-muted fs-12">
              <strong className="text-dark">Choose Your New Mattress:</strong>{" "}
              Choose your new mattress from our wide range of mattresses and
              pick your favorite.
            </p>
            <p className="mb-2 text-muted fs-12">
              <strong className="text-dark">Exchange:</strong> Toss your old
              mattress away for a brand new one and enjoy up to 20% off!
            </p>
            <hr />
            <h5 className="mb-3 text-dark">
              How to Participate in Mattress Exchange Offer?
            </h5>
            <ol className="fs-12 text-muted">
              <li className="mb-2">
                Visit any Danube Home store or our website to buy a mattress.
              </li>
              <li className="mb-2">
                Sign up for #GreatMattressExchange by scanning a QR code (On the
                mattress) to generate a discount code and fill up a form to
                avail of a free donation pickup. Tick the box (Terms &
                Conditions, I agree).
              </li>
              <li className="mb-2">
                Share the code with the staff in Danube Home or apply the
                discount code at checkout (for online purchases) to get up to
                20% additional discount.
              </li>
              <li className="mb-2">
                Get up to 20% instant discount on all Danube Home mattresses.
              </li>
              <li className="mb-2">
                Donate your preloved mattress from the comfort of your home and
                make a difference. 
              </li>
            </ol>
            <hr />
            <h5 className="mb-3 text-dark">Terms & Conditions:</h5>
            <ul className="fs-12 text-muted">
              <li className="mb-2">
                The mattress pickup will be free of charge, courtesy of Danube
                Home, based on the pickup availability in your zone at the time
                of delivery of the New Mattress. In the case of multiple
                mattresses, the charges for additional items would be borne by
                the customer.
              </li>
              <li className="mb-2">
                Doorstep pick-ups are available in limited areas and as per the
                delivery location.
              </li>
              <li className="mb-2">
                The mattress should be in reasonable condition (Bug-free).
              </li>
              <li className="mb-2">
                The status of your pick-up request will be informed to you via
                e-mail.
              </li>
              <li className="mb-2">
                Danube Home reserves the right to modify, alter, or withdraw the
                campaign terms at any time without prior notice.
              </li>
              <li className="mb-2">
                The campaign is LIVE for a limited period only. Please check
                with the store for more details.
              </li>
              <li className="mb-2">
                The discount up to 20% varies across different models.
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default ExchangeInfo;
