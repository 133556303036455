import React, { useEffect } from "react";

const HtmlThemeSwitcher = ({ theme }) => {
  useEffect(() => {
    const html = document.documentElement;
    html.setAttribute("data-dh-theme", theme);
  }, [theme]);

  return null;
};

export default HtmlThemeSwitcher;
