import React from "react";
import { Button, Col, Container, Row } from "reactstrap";

function Header() {
  const openInNewWindow = () => {
    window.open("https://www.danubehome.com/ae/en", "_blank");
  };
  return (
    <header
      className="py-2 border-bottom bg-white position-sticky top-0 shadow"
      style={{ zIndex: 9 }}
    >
      <Container>
        <Row className="row-cols-auto justify-content-between align-items-center">
          <Col>
            <img
              src="/assets/logoMob.svg"
              alt="danube home"
              title="danube home"
              width={38}
              height={31}
              className="d-md-none d-block"
            />
            <img
              src="/assets/logo.svg"
              alt="danube home"
              title="danube home"
              width={244}
              height={42}
              className="d-md-block d-none"
            />
          </Col>
          <Col className="text-end">
            <Button
              size="xl"
              color="dark"
              className="rounded-pill"
              onClick={openInNewWindow}
            >
              Back to Danubehome.com
            </Button>
          </Col>
        </Row>
      </Container>
    </header>
  );
}

export default Header;
