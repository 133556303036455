// src/Footer.js
import React from "react";
import { Col, Container, Row } from "reactstrap";

function Footer() {
  return (
    <footer className="bg-dark">
      <Container>
        <Row>
          <Col className="text-center py-3">
            <p className="mb-0 text-light">
              Copyright © 2024 Danube Home. All rights reserved.
            </p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

export default Footer;
