import React from "react";
import { Col, Container, Row } from "reactstrap";
import "./HomePage.scss";
import CustomerForm from "../../components/CustomerForm/CustomerForm";
import ExchangeInfo from "../../components/ExchangeInfo/ExchangeInfo";
const HomePage = () => {
  return (
    <>
      <section className="form-bg">
        <CustomerForm />
      </section>
      <ExchangeInfo />
    </>
  );
};
export default HomePage;
